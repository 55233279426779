import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Row,
  Col
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import {
    billedToOptions,
    invoicePhoneNumbers,
    invoiceBusinessLicences
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
    fetchSingleInvoice
} from "../../redux/Invoices/InvoicesAction";
import moment from 'moment';
import Moment from "react-moment";
import '../../assets/css/view-invoice.css'
import qs from "query-string";

function ViewInvoice(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const { match: { params } } = props;
    let paramQuery = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    console.log(paramQuery)
    useEffect(() => {
        props.fetchSingleInvoice(Object.assign(params, paramQuery))
    }, []);

    //Error Handler from Api and unauthorized check
    if (props.invoice.error && !unauthorized) {
        if (props.invoice.error.status === 403) {
            setUnauthorized(true);
        } else if (props.invoice.error.status === 440) {
            props.resetState();
            toast.error(props.invoice.error.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            props.history.push("/auth/login");
        }
    }

    const renderAllItemsHTML = (e) => {
        return props.invoice.items.map((item, i) => {
            let deliveryDate = moment(props.invoice.model.deliveryDate*1000);
            return (
              <tr key={i} id={"invoice-item-row" + i}>
                <td className="input-td">{item.itemDescription}</td>
                <td className="input-td">{item.dateTime}</td>
                <td className="input-td">{moment(item.dateTime).add(31, 'days').format('MM/DD/YYYY')}</td>
                <td className="input-td">{parseInt(item.price)}</td>
              </tr>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
            {/* Page content */}
            <Container className="page-main-content" fluid>
                <br/>
                <div className="block-center leftmargin">
                    <img style={{maxHeight: '160px'}} src={require("assets/img/LOGO-medium.png")}/>
                    <div className="text-center font-weight-900 font-size-19 grey">
                        4065 S. Jones Blvd <br/>
                        Las Vegas, NV 89103 <br/>
                        702-998-4000
                    </div>
                    <div className="invoice-title">INVOICE/RECEIPT</div>
                    <div className="font-size-19" style={{marginRight:'120px', float: 'right'}}>
                    <span className="font-weight-900">Total:</span>&nbsp;${props.invoice.items
                                            .reduce((prev, curr) => {
                                                let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
                                                return prev + price;
                                            }, 0)
                                            .toFixed(0)}<br/>
                        <span className="font-weight-900">Gold Spot Price:</span>&nbsp;${parseInt(props.invoice.model.goldSpotPrice)}
                    </div>
                    <div className="font-size-19">
                        <div className="">
                            <span className="font-weight-900">Billed To:</span> {billedToOptions[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Phone #:</span> {invoicePhoneNumbers[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Business License #:</span> {invoiceBusinessLicences[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Invoice #:</span> {params.id}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Check #:</span> {props.invoice.model.checkNumber}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Date: </span>
                            <Moment format="MM/DD/YYYY" tz="America/Los_Angeles">
                                {props.invoice.model.creationDate.toString().length === 10
                                        ? new Date(props.invoice.model.creationDate * 1000)
                                        : new Date(props.invoice.model.creationDate)}
                            </Moment>
                        </div>
                    </div>

                    <br/>

___________________________________________________________________________________________________ <br/>
    By signing this invoice I acknowledge that I have not taken possession of any item(s) prior to the thirty (30) day rule as enforced by the Metropolitan Police Department.  The delivery date on this invoice is at least 31 days after the original date of purchase by seller and is the day that I am taking delivery.
                </div>
                <Table className="create-invoice-table invoice-items-list-table leftmargin" bordered striped responsive="sm" style={{width: '80%'}}>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="description-col">Description</th>
                            <th scope="col" className="orignal-transaction-date-col">Original Transaction Date</th>
                            <th scope="col" className="delivery-date-col">Delivery Date</th>
                            <th scope="col" className="amount-col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>{renderAllItemsHTML()}</tbody>
                    <tfoot className="tfoot-light">
                        <tr>
                            <td scope="col" colSpan="3">Total:</td>
                            <td scope="col">
                            ${props.invoice.items
                                .reduce((prev, curr) => {
                                    let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
                                    return prev + price;
                                }, 0)
                                .toFixed(2)}
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        invoice: state.invoicesReducer.invoice,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleInvoice: (id) => dispatch(fetchSingleInvoice(id)),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);
