import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Row,
  Col
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import {
    billedToOptions,
    invoicePhoneNumbers,
    invoiceBusinessLicences
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
    saveInvoiceItemDetails ,
    fetchSingleCoinInvoice
} from "../../redux/Invoices/InvoicesAction";
import moment from 'moment';
import Moment from "react-moment";
import '../../assets/css/view-invoice.css'
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";

function ViewCoinInvoice(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [loading, setLoading] = useState(false);
    const { match: { params } } = props;
    const [newItems, setNewItems] = useState(false);

    useEffect(() => {
        props.fetchSingleCoinInvoice(params.id)
        setNewItems([...props.invoice.items])
    }, []);

    const printHandler = (item) => {
        setLoading(true);
        PostApiWithAuthorizationAction("invoices/print-label/", {
          ...item,
          station: localStorage.getItem('lastUsedPrinter') || 'station1',
          invoiceId: params.id
        }).then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
      };

      const formatAmount = (amount) => {
        var parts = amount.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
      }

      const getTotalAmount = () => {
        return props.invoice.items
        .reduce((prev, curr) => {
            let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
            return prev + price;
        }, 0)
      }
  
      const getDiscountAmount = () => {
        return props.invoice.model.applyDiscount ? getTotalAmount() * 0.1 : 0
      }

      const changeItemValues = (name, value, index) => {
        let temp = props.invoice.items;
        let itemObj = temp[index];
        itemObj[name] = value;
        temp[index] = itemObj;
        props.saveInvoiceItemDetails(temp);
        setNewItems([... temp]);
        updateInvoiceItems(props.invoice.model.id)
      };

      const updateInvoiceItems = (id) => {
        PostApiWithAuthorizationAction("invoices/update-items/" + id, {
            type: 2,
            items: props.invoice.items
        })
        .then((res) => {
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }

    //Error Handler from Api and unauthorized check
    if (props.invoice.error && !unauthorized) {
        if (props.invoice.error.status === 403) {
            setUnauthorized(true);
        } else if (props.invoice.error.status === 440) {
            props.resetState();
            toast.error(props.invoice.error.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            props.history.push("/auth/login");
        }
    }

    const renderAllItemsHTML = (e) => {
        return props.invoice.items.map((item, i) => {
            return (
              <tr key={i} id={"invoice-item-row" + i}>
                <td className="input-td" style={{textTransform: 'capitalize'}}>{item.type} {item.id}</td>
                <td className="input-td" style={{textTransform: 'capitalize'}}>{item.typeId} {item.typeValue}</td>
                <td className="input-td">{item.qty}</td>
                <td className="input-td">{item.itemDescription}</td>
                <td className="input-td">${(item.price / item.qty).toFixed(2).replace('.00', '')}</td>
                <td className="input-td">${parseInt(item.price)}</td>
                <td className="input-td">${item.estimate}</td>
                <td scope="col">
                    <Button
                        id="print"
                        className="icon-cross"
                        color="warning"
                        title="print"
                        onClick={() => {
                            printHandler(item)
                        }}
                    >
                    <i class="fas fa-print"></i>
                    </Button>
                </td>
                <td scope="col">
                    <Input
                        id="notes"
                        type="text"
                        value={item.notes}
                        className={classnames({
                            "input-null": item.notes === "",
                        })}
                        style={{ height: '30px', width: "80px" }}
                        onChange={(e) => {
                            changeItemValues("notes", e.target.value, i);
                        }}
                    />
                </td>
              </tr>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
            {/* Page content */}
            <Container className="page-main-content page-view-invoice" fluid>
                <br/>
                <div className="block-center leftmargin">
                    {(props.invoice.model.showInvoiceLogo) && (
                        <>
                            <img style={{maxHeight: '160px'}} src={require("assets/img/LOGO-medium.png")}/>
                            <div className="text-center font-weight-900 font-size-19 grey">
                                4065 S. Jones Blvd <br/>
                                Las Vegas, NV 89103 <br/>
                                702-998-4000
                            </div>
                        </>
                    )}
                    <div className="invoice-title">INVOICE/RECEIPT</div>
                    <div className="font-size-19" style={{marginRight:'120px', float: 'right'}}>
                        {(props.invoice.totalAmount > 0) && (
                            <>
                            <span className="font-weight-900">Total:</span>&nbsp;${formatAmount(getTotalAmount() - getDiscountAmount())}<br/>
                            </>
                        )}
                        <span className="font-weight-900">Gold Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.goldSpotPrice))}<br/>
                        <span className="font-weight-900">Silver Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.silverSpotPrice))}<br/>
                        <span className="font-weight-900">Platinum Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.platinumSpotPrice))}<br/>
                        <span className="font-weight-900">Palladium Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.palladiumSpotPrice))}
                    </div>
                    <div className="font-size-19">
                        <div className="">
                            <span className="font-weight-900">Billed To:</span> {billedToOptions[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Phone #:</span> {invoicePhoneNumbers[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Business License #:</span> {invoiceBusinessLicences[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Invoice #:</span> {params.id}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Check #:</span> {props.invoice.model.checkNumber}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Date: </span>
                            <Moment format="MM/DD/YYYY" tz="America/Los_Angeles">
                                {props.invoice.model.creationDate.toString().length === 10
                                        ? new Date(props.invoice.model.creationDate * 1000)
                                        : new Date(props.invoice.model.creationDate)}
                            </Moment>
                        </div>
                    </div>

                    <br/>

___________________________________________________________________________________________________ <br/>
                </div>
                <Table className="create-invoice-table invoice-items-list-table leftmargin" bordered striped responsive="sm" style={{width: '80%'}}>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="qty-col">ID</th>
                            <th scope="col" className="qty-col">Bag #</th>
                            <th scope="col" className="qty-col">Qty</th>
                            <th scope="col" className="description-col">Description</th>
                            <th scope="col" className="amount-per-col">Amt Per</th>
                            <th scope="col" className="amount-col">Amount</th>
                            <th scope="col" className="delivery-date-col">Estimate</th>
                            <th scope="col" className="delivery-date-col"></th>
                            <th scope="col" className="qty-col">Notes</th>
                        </tr>
                    </thead>
                    <tbody>{renderAllItemsHTML()}</tbody>
                    <tfoot className="tfoot-light">
                        {(props.invoice.model.applyDiscount) && (
                        <tr>
                            <td scope="col" colSpan={4}>Discount:</td>
                            <td scope="col">
                                ${formatAmount(getDiscountAmount().toFixed(2).replace('.00', ''))}
                            </td>
                            <td scope="col"> </td>
                            <td scope="col"></td>
                        </tr>
                        )}
                        <tr>
                            <td scope="col" colSpan={4}>Total:</td>
                            <td scope="col">
                                ${formatAmount((getTotalAmount()-getDiscountAmount()).toFixed(2).replace('.00', ''))}
                            </td>
                            <td scope="col">
                                ${formatAmount(props.invoice.items
                                        .reduce((prev, curr) => {
                                            let price = !isNaN(curr.estimate) ? parseFloat(curr.estimate) : 0.0
                                            return prev + price;
                                        }, 0))}
                            </td>
                            <td scope="col"></td>
                        </tr>
                    </tfoot>
                </Table>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        invoice: state.invoicesReducer.invoice,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleCoinInvoice: (id) => dispatch(fetchSingleCoinInvoice(id)),
        saveInvoiceItemDetails: (body) => dispatch(saveInvoiceItemDetails(body)),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ViewCoinInvoice);
